<template>
  <div class="school">
    <div class="school__classes">
      <nuxt-link
        v-for="item in schoolClasses"
        :key="item"
        to="/online-school"
        class="school__classes-card"
      >
        <span class="school__classes-card-num">
          {{ item }}
        </span>

        <span class="school__classes-card-text">
          класс
        </span>
      </nuxt-link>
    </div>

    <div
      class="school__free"
      role="button"
      tabindex="0"
      @click="toggleFreeSchoolLead"
    >
      <div class="school__free-bg">
        <img
          src="/v2/header/old/dropdown/school/free.png"
          alt="heart"
          class="school__free-bg--image"
        >
        <img
          src="/v2/header/old/dropdown/school/free-mobile.png"
          alt="heart"
          class="school__free-bg--image-mobile"
        >
      </div>

      <img
        src="/v2/header/old/dropdown/school/free-title.svg"
        alt="бесплатный пробный день"
        class="school__free-title"
      >
      <p class="school__free-text">
        Посетите реальные уроки, познакомьтесь с учителями и оцените качество обучения
      </p>
    </div>

    <a
      href="/online-school?licenses=1"
      class="school__card license"
    >
      <div class="school__card-bg" />
      <h6 class="school__card-title">
        <img
          src="/v2/header/old/dropdown/school/arrow.svg"
          alt="arrow"
        >
        гос. лицензия и аккредитация
      </h6>

      <p class="school__card-text">
        Мы выдаем аттестаты гос. образца
      </p>

      <img
        src="/v2/header/old/dropdown/school/russia.svg"
        alt="russia"
        class="school__card-image"
      >
    </a>

    <nuxt-link
      to="/otzyvy"
      class="school__card how"
    >
      <div class="school__card-bg" />

      <h6 class="school__card-title">
        <img
          src="/v2/header/old/dropdown/school/arrow.svg"
          alt="arrow"
        >
        реальные отзывы учеников и родителей
      </h6>

      <p class="school__card-text">
        Нас выбирают родители и дети
        <br>
        со всего мира.
        Доверьтесь нам,
        <br>
        как доверились они!
      </p>
    </nuxt-link>

    <nuxt-link
      to="/proforientacia"
      class="school__card documents"
    >
      <div class="school__card-bg" />

      <h6 class="school__card-title">
        <img
          src="/v2/header/old/dropdown/school/arrow.svg"
          alt="arrow"
        >
        ПРОФОРИЕНТАЦИЯ
        <br>
        для 5-11 классов
      </h6>

      <p class="school__card-text">
        Запишитесь на онлайн-консультацию с экспертом по профориентации.
        <br>
        <span>Начните бесплатно с профтеста</span>
      </p>
    </nuxt-link>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'DropdownSchoolContent',
  data: () => ({
    schoolClasses: [5, 6, 7, 8, 9, 10, 11],
  }),

  methods: {
    ...mapActions('headerModals', [
      'toggleFreeSchoolLead',
      'toggleFreeSchoolDocumentsLead',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.school {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 3fr 1.45fr;
  grid-gap: 10px;

  @media (max-width: 1220px) {
    grid-template-rows: 2fr 1.5fr;
  }

  @include media-down(laptop) {
    grid-template-rows: auto auto;
  }

  @include media-down(tablet) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, auto);
  }

  &__classes {
    grid-column: 1 / 3;
    grid-row: 1;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 12px;

    @include media-down(tablet) {
      grid-column: 1;
      grid-row: 1;

      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }

    &-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      border-radius: 20px;
      background-color: $header-card-bg;
      box-shadow: 2px 2px 10px 0 rgba(87, 106, 135, 0.02) inset, 8px 8px 30px 0 rgba(87, 106, 135, 0.02) inset;

      transition: margin .2s ease-out, width .2s ease-out, height .2s ease-out;

      @include media-down(tablet) {
        width: 72px;
        height: 72px;
      }

      &-num {
        font-feature-settings: 'case' on;
        font-size: 40px;
        font-weight: 900;
        line-height: 100%;
        letter-spacing: -0.8px;
        text-transform: uppercase;
        text-align: center;
        color: $black-color;

        transition: color .2s ease-out, transform .2s ease-out, text-stroke .2s ease-out;

        @include media-down(laptop) {
          font-size: 34px;
        }

        @include media-down(tablet) {
          font-size: 30px;
        }
      }

      &-text {
        font-variant-numeric: lining-nums proportional-nums;
        font-size: 20px;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: -0.2px;
        text-align: center;
        color: $black-color;

        @include media-down(laptop) {
          font-size: 16px;
        }

        @include media-down(tablet) {
          font-weight: 600;
        }
      }

      &:hover {
        margin: 2px;

        @include media-down(tablet) {
          width: 68px;
          height: 68px;
        }

        .school__classes-card-num {
          color: $header-orange;
          -webkit-text-stroke: 1.5px $black-color;
          text-stroke: 1.5px $black-color;
          transform: rotate(-15deg);
        }
      }
    }
  }

  &__free {
    grid-column: 3;
    grid-row: 1;
    position: relative;
    overflow: hidden;

    border-radius: 20px;

    cursor: pointer;
    transition: transform .2s ease-out;

    @include media-down(tablet) {
      grid-column: 1;
      grid-row: 5;
      height: 226px;
    }

    &-bg {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      overflow: hidden;

      border-radius: 20px;
      background-color: $header-card-bg;

      transition: .2s ease-out;

      &--image {
        width: 100%;
        height: 100%;

        @include media-down(tablet) {
          display: none;
        }

        &-mobile {
          display: none;

          @include media-down(tablet) {
            display: block;
            position: absolute;
            bottom: 0;
            right: 0;
          }
        }
      }
    }

    &-title {
      position: absolute;
      top: 20px;
      left: 12px;
      z-index: 1;

      @media (max-width: 1220px) {
        max-width: 100%;
        left: 7px;
      }

      @include media-down(laptop) {
        max-width: 90%;
        top: 10px;
      }

      @include media-down(tablet) {
        top: 16px;
      }
    }

    &-text {
      position: absolute;
      top: 72px;
      left: 20px;
      z-index: 1;
      width: 78.43%;

      font-size: 16px;
      font-weight: 600;
      line-height: 110%;
      letter-spacing: -0.16px;
      color: $grey2;

      transition: color .2s ease-out;

      @media (max-width: 1220px) {
        left: 15px;
        font-size: 14px;
      }

      @include media-down(laptop) {
        top: 57px;
        left: 10px;
      }

      @include media-down(tablet) {
        top: 68px;
        left: 16px;
        font-size: 16px;
      }
    }

    &:hover {
      .school__free-bg {
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        left: 2px;
        top: 2px;
      }
      .school__free-text {
        color: $black-color;
      }
    }
  }

  &__card {
    position: relative;
    padding: 18px;
    overflow: hidden;

    border-radius: 20px;

    cursor: pointer;

    @media (max-width: 1220px) {
      padding: 15px;
    }

    @include media-down(laptop) {
      padding: 10px;
    }

    @include media-down(tablet) {
      padding: 16px;
      min-height: 138px;
    }

    &-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      background-color: $header-card-bg;
      border-radius: 20px;
      box-shadow: 2px 2px 10px 0 rgba(87, 106, 135, 0.02) inset, 8px 8px 30px 0 rgba(87, 106, 135, 0.02) inset;

      transition: width .2s ease-out, height .2s ease-out, top .2s ease-out, left .2s ease-out;
    }

    &-title {
      position: relative;
      z-index: 1;
      min-height: 44px;

      font-variant-numeric: lining-nums proportional-nums;
      font-size: 20px;
      font-weight: 700;
      line-height: 110%;
      letter-spacing: -0.2px;
      text-transform: uppercase;
      color: $black-color;

      @media (max-width: 1220px) {
        min-height: 1px;

        font-size: 18px;
      }

      @include media-down(laptop) {
        font-size: 15px;
      }

      @include media-down(tablet) {
        font-size: 20px;
      }

      img {
        display: inline;
        transform: translateY(1.5px);

        @media (max-width: 1220px) {
          max-height: 16px;
          transform: translateY(0);
        }

        @include media-down(laptop) {
          max-height: 12px;
        }

        @include media-down(tablet) {
          max-height: 16px;
        }
      }
    }

    &-text {
      position: relative;
      z-index: 1;
      margin-top: 8px;

      font-variant-numeric: lining-nums proportional-nums;
      font-size: 16px;
      font-weight: 600;
      line-height: 110%;
      letter-spacing: -0.16px;
      color: $grey2;

      transition: color .2s ease-out;

      @media (max-width: 1220px) {
        font-size: 14px;
      }

      @include media-down(tablet) {
        font-size: 16px;
      }

      span {
        color: $header-dark-orange;
      }
    }

    &-image {
      position: absolute;
      right: 0;
      bottom: 0;
    }

    &:hover {
      .school__card-bg {
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        top: 2px;
        left: 2px;
      }
      .school__card-subtitle {
        color: $black-color;
      }
      .school__card-text {
        color: $black-color;
      }
    }
  }
}
</style>
