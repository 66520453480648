<template>
  <div class="external">
    <nuxt-link
      to="/externat"
      class="external__card child"
    >
      <div class="external__card-bg" />

      <p class="external__card-subtitle">
        5-11 класс
      </p>

      <h6 class="external__card-title">
        <img
          src="/v2/header/old/dropdown/external/arrow.svg"
          alt="arrow"
        >
        Экстернат для детей
      </h6>

      <p class="external__card-text">
        Поможем ребенку пройти школьную программу в своем
        или в ускоренном темпе. Два класса за один год — это реально
      </p>
    </nuxt-link>

    <div
      class="external__free"
      role="button"
      tabindex="0"
      @click="toggleFreeExternalLead"
    >
      <div class="external__free-bg">
        <img
          src="/v2/header/old/dropdown/external/free.png"
          alt="heart"
          class="external__free-bg--image"
        >
        <img
          src="/v2/header/old/dropdown/external/free-mobile.png"
          alt="heart"
          class="external__free-bg--image-mobile"
        >
      </div>

      <img
        src="/v2/header/old/dropdown/external/free-title.svg"
        alt="бесплатный пробный день"
        class="external__free-title"
      >
      <p class="external__free-text">
        Оцените, насколько вам подходит ускоренное обучение
      </p>
    </div>

    <nuxt-link
      to="/externat-dlya-vzroslyh"
      class="external__card adult"
    >
      <div class="external__card-bg" />

      <p class="external__card-subtitle">
        5-11 класс
      </p>

      <h6 class="external__card-title">
        <img
          src="/v2/header/old/dropdown/external/arrow.svg"
          alt="arrow"
        >
        Экстернат для взрослых
      </h6>

      <p class="external__card-text">
        Получить аттестат можно в любом возрасте, уделяя примерно 1-2 часа в день.
        Мы поможем подготовиться и примем аттестацию
      </p>
    </nuxt-link>

    <nuxt-link
      to="/school/realno-li-zakonchit-i-klass-shkoly-za-god-plyusy-i-minusy-eksternata"
      class="external__card fast"
    >
      <div class="external__card-bg" />

      <h6 class="external__card-title">
        <img
          src="/v2/header/old/dropdown/external/arrow.svg"
          alt="arrow"
        >
        как закончить школу быстрее?
      </h6>

      <p class="external__card-text">
        Кому подходит экстернат и как перевестись в нашу онлайн-школу
      </p>
    </nuxt-link>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'DropdownExternalContent',

  methods: {
    ...mapActions('headerModals', [
      'toggleFreeExternalLead',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.external {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 0.348fr 0.652fr;
  grid-gap: 10px;

  @media (max-width: 1220px) {
    grid-template-rows: 1fr 0.05fr 0.95fr;
  }

  @include media-down(laptop) {
    grid-template-columns: 1.5fr 1fr;
  }

  @include media-down(tablet) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto);
  }

  &__card {
    position: relative;
    padding: 20px;

    border-radius: 20px;

    cursor: pointer;

    @media (max-width: 1220px) {
      padding: 15px;
    }

    @include media-down(laptop) {
      padding: 10px;
    }

    @include media-down(tablet) {
      padding: 16px;
      min-height: 138px;
    }

    &-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      background-color: $header-card-bg;
      border-radius: 20px;
      box-shadow: 2px 2px 10px 0 rgba(87, 106, 135, 0.02) inset, 8px 8px 30px 0 rgba(87, 106, 135, 0.02) inset;

      transition: width .2s ease-out, height .2s ease-out, top .2s ease-out, left .2s ease-out;
    }

    &-subtitle {
      position: relative;
      z-index: 1;
      margin-bottom: 8px;

      font-variant-numeric: lining-nums proportional-nums;
      font-feature-settings: 'case' on;
      font-size: 14px;
      font-weight: 500;
      line-height: 110%;
      letter-spacing: -0.28px;
      color: $grey2;

      transition: color .2s ease-out;
    }

    &-title {
      position: relative;
      z-index: 1;

      font-variant-numeric: lining-nums proportional-nums;
      font-size: 20px;
      font-weight: 700;
      line-height: 110%;
      letter-spacing: -0.2px;
      text-transform: uppercase;
      color: $black-color;

      @media (max-width: 1220px) {
        font-size: 18px;
      }

      @include media-down(laptop) {
        font-size: 15px;
      }

      @include media-down(tablet) {
        font-size: 20px;
      }

      img {
        display: inline;
        transform: translateY(1.5px);

        @media (max-width: 1220px) {
          max-height: 16px;
          transform: translateY(0);
        }

        @include media-down(laptop) {
          max-height: 12px;
        }

        @include media-down(tablet) {
          max-height: 16px;
        }
      }
    }

    &-text {
      position: relative;
      z-index: 1;
      max-width: 286px;
      margin-top: 8px;

      font-variant-numeric: lining-nums proportional-nums;
      font-size: 16px;
      font-weight: 600;
      line-height: 110%;
      letter-spacing: -0.16px;
      color: $grey2;

      transition: color .2s ease-out;

      @media (max-width: 1220px) {
        font-size: 14px;
      }

      @include media-down(tablet) {
        font-size: 16px;
      }
    }

    &:hover {
      .external__card-bg {
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        top: 2px;
        left: 2px;
      }
      .external__card-subtitle {
        color: $black-color;
      }
      .external__card-text {
        color: $black-color;
      }
    }
  }
  &__card.child {
    grid-column: 1;
    grid-row: 1;

    @include media-down(tablet) {
      grid-column: 1;
      grid-row: 1;
      min-height: 226px;
    }
  }
  &__card.adult {
    grid-column: 1;
    grid-row: 2 / 4;
    min-height: 226px;

    @include media-down(tablet) {
      grid-column: 1;
      grid-row: 2;
    }
  }
  &__card.fast {
    grid-column: 2;
    grid-row: 3;

    @include media-down(tablet) {
      grid-column: 1;
      grid-row: 3;
    }
  }

  &__free {
    grid-column: 2;
    grid-row: 1 / 3;
    position: relative;
    overflow: hidden;

    border-radius: 20px;

    cursor: pointer;
    transition: transform .2s ease-out;

    @include media-down(tablet) {
      grid-column: 1;
      grid-row: 4;
      height: 226px;
      overflow: hidden;

      border-radius: 20px;
    }

    &-bg {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      overflow: hidden;

      border-radius: 20px;
      background-color: $header-card-bg;

      transition: .2s ease-out;

      &--image {
        width: 100%;
        height: 100%;

        @include media-down(tablet) {
          display: none;
        }

        &-mobile {
          display: none;

          @include media-down(tablet) {
            display: block;
            position: absolute;
            bottom: 0;
            right: 0;
          }
        }
      }
    }

    &-title {
      position: absolute;
      z-index: 1;
      top: 20px;
      left: 12px;

      @media (max-width: 1220px) {
        max-width: 100%;
        left: 7px;
      }

      @include media-down(laptop) {
        max-width: 90%;
        top: 10px;
      }

      @include media-down(tablet) {
        top: 16px;
      }
    }

    &-text {
      position: absolute;
      z-index: 1;
      top: 72px;
      left: 20px;
      width: 78.43%;

      font-size: 16px;
      font-weight: 600;
      line-height: 110%;
      letter-spacing: -0.16px;
      color: $grey2;

      transition: color .2s ease-out;

      @media (max-width: 1220px) {
        left: 15px;
        font-size: 14px;
      }

      @include media-down(laptop) {
        top: 57px;
        left: 10px;
      }

      @include media-down(tablet) {
        top: 68px;
        left: 16px;
        font-size: 16px;
      }
    }

    &:hover {
      .external__free-bg {
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        left: 2px;
        top: 2px;
      }
      .external__free-text {
        color: $black-color;
      }
    }
  }
}
</style>
