<template>
  <div class="child">
    <nuxt-link
      to="/soft-skills"
      class="child__card soft"
    >
      <div class="child__card-bg" />

      <p class="child__card-subtitle">
        8-18 лет
      </p>

      <h6 class="child__card-title">
        <img
          src="/v2/header/old/dropdown/child/arrow.svg"
          alt="arrow"
        >
        Курс по развитию гибких навыков
      </h6>

      <p class="child__card-text">
        Развейте эмоциональный интеллект, ораторское искусство,
        критическое мышление и другие
      </p>
    </nuxt-link>

    <nuxt-link
      to="/proforientacia"
      class="child__card professional"
    >
      <div class="child__card-bg" />

      <h6 class="child__card-title">
        <img
          src="/v2/header/old/dropdown/child/arrow.svg"
          alt="arrow"
        >
        ПРОФОРИЕНТАЦИЯ для 5-11 классов
      </h6>

      <p class="child__card-text">
        Запишитесь на онлайн-консультацию с экспертом по профориентации.
        <br>
        <span>Начните бесплатно с профтеста</span>
      </p>
    </nuxt-link>

    <div
      class="child__card blog"
      role="button"
      tabindex="0"
      @click="toggleFreeChildBlogLead"
    >
      <div class="child__card-bg" />

      <h6 class="child__card-title">
        <img
          src="/v2/header/old/dropdown/child/arrow.svg"
          alt="arrow"
        >
        Блогинг для детей
      </h6>

      <p class="child__card-text">
        Научим безопасному блогингу и поможем создать свое медиапортфолио
      </p>
    </div>

    <div
      class="child__card tutor"
      role="button"
      tabindex="0"
      @click="toggleFreeChildPersonalLead"
    >
      <div class="child__card-bg" />

      <h6 class="child__card-title">
        <img
          src="/v2/header/old/dropdown/child/arrow.svg"
          alt="arrow"
        >
        Персональный наставник-тьютор
      </h6>

      <p class="child__card-text">
        Составит ребенку индивидуальный образовательный
        маршрут для успешного и комфортного обучения
      </p>
    </div>

    <div
      class="child__card language"
      role="button"
      tabindex="0"
      @click="toggleFreeChildLanguageLead"
    >
      <div class="child__card-bg" />

      <h6 class="child__card-title">
        <img
          src="/v2/header/old/dropdown/child/arrow.svg"
          alt="arrow"
        >
        языковые курсы
      </h6>

      <p class="child__card-text">
        Обучим английскому и китайскому у топовых учителей и носителей языка
      </p>
    </div>

    <div
      class="child__card help"
      role="button"
      tabindex="0"
      @click="openVideoModal('https://www.youtube.com/embed/eOQbX6ZuB28?si=sg8wmesWpfMKqQW5')"
    >
      <div class="child__card-bg" />

      <h6 class="child__card-title">
        <img
          src="/v2/header/old/dropdown/child/arrow.svg"
          alt="arrow"
        >
        Что такое Гибкие навыки и как они помогают в жизни?
      </h6>
    </div>

    <div
      class="child__free"
      role="button"
      tabindex="0"
      @click="toggleFreeChildLead"
    >
      <div class="child__free-bg">
        <img
          src="/v2/header/old/dropdown/child/free.png"
          alt="heart"
          class="child__free-bg--image"
        >
        <img
          src="/v2/header/old/dropdown/child/free-mobile.png"
          alt="heart"
          class="child__free-bg--image-mobile"
        >
      </div>

      <img
        src="/v2/header/old/dropdown/child/free-title.svg"
        alt="Диагностика гибких навыков бесплатно"
        class="child__free-title"
      >
      <p class="child__free-text">
        Узнайте сильные и слабые стороны ребенка, получите
        персональный план развития гибких социальных навыков
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'DropdownChildContent',

  methods: {
    ...mapActions('headerModals', [
      'toggleFreeChildLead',
      'toggleFreeChildProfessionalLead',
      'toggleFreeChildBlogLead',
      'toggleFreeChildLanguageLead',
      'toggleFreeChildPersonalLead',
    ]),
    ...mapActions('modals', {
      openVideoModal: 'openVideo',
    }),
  },
};
</script>

<style lang="scss" scoped>
.child {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 10px;

  @media (max-width: 1220px) {
    grid-template-rows: 1fr 0.6fr 0.6fr;
  }

  @include media-down(laptop) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, auto);
  }

  @include media-down(tablet) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, auto);
  }

  &__card {
    position: relative;
    padding: 20px;

    border-radius: 20px;

    cursor: pointer;

    @media (max-width: 1220px) {
      padding: 15px;
    }

    @include media-down(laptop) {
      padding: 10px;
    }

    @include media-down(tablet) {
      padding: 16px;
    }

    &-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      background-color: $header-card-bg;
      border-radius: 20px;
      box-shadow: 2px 2px 10px 0 rgba(87, 106, 135, 0.02) inset, 8px 8px 30px 0 rgba(87, 106, 135, 0.02) inset;

      transition: width .2s ease-out, height .2s ease-out, top .2s ease-out, left .2s ease-out;
    }

    &-subtitle {
      position: relative;
      z-index: 1;
      margin-bottom: 8px;

      font-variant-numeric: lining-nums proportional-nums;
      font-feature-settings: 'case' on;
      font-size: 14px;
      font-weight: 500;
      line-height: 110%;
      letter-spacing: -0.28px;
      color: $grey2;

      transition: color .2s ease-out;
    }

    &-title {
      position: relative;
      z-index: 1;

      font-variant-numeric: lining-nums proportional-nums;
      font-size: 20px;
      font-weight: 700;
      line-height: 110%;
      letter-spacing: -0.2px;
      text-transform: uppercase;
      color: $black-color;

      @media (max-width: 1220px) {
        font-size: 18px;
      }

      @include media-down(laptop) {
        font-size: 15px;
      }

      @include media-down(tablet) {
        font-size: 20px;
      }

      img {
        display: inline;
        transform: translateY(1.5px);

        @media (max-width: 1220px) {
          max-height: 16px;
          transform: translateY(0);
        }

        @include media-down(laptop) {
          max-height: 12px;
        }

        @include media-down(tablet) {
          max-height: 16px;
        }
      }
    }

    &-text {
      position: relative;
      z-index: 1;
      margin-top: 8px;

      font-variant-numeric: lining-nums proportional-nums;
      font-size: 16px;
      font-weight: 600;
      line-height: 110%;
      letter-spacing: -0.16px;
      color: $grey2;

      transition: color .2s ease-out;

      @media (max-width: 1220px) {
        font-size: 14px;
      }

      @include media-down(tablet) {
        font-size: 16px;
      }

      span {
        color: #D56FAC;
      }
    }

    &:hover {
      .child__card-bg {
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        top: 2px;
        left: 2px;
      }
      .child__card-subtitle {
        color: $black-color;
      }
      .child__card-text {
        color: $black-color;
      }
    }
  }

  &__card.soft {
    grid-column: 1;
    grid-row: 1 / 3;

    @include media-down(laptop) {
      grid-row: 1;
    }

    @include media-down(tablet) {
      grid-column: 1;
      grid-row: 1;
    }
  }
  &__card.professional {
    grid-column: 2;
    grid-row: 1;

    @include media-down(laptop) {
      grid-column: 1;
      grid-row: 2;
    }

    @include media-down(tablet) {
      grid-column: 1;
      grid-row: 3;
      min-height: 138px;
    }
  }
  &__card.blog {
    grid-column: 2;
    grid-row: 2;

    @include media-down(laptop) {
      grid-column: 1;
      grid-row: 3;
    }

    @include media-down(tablet) {
      grid-column: 1;
      grid-row: 4;
      min-height: 138px;
    }
  }
  &__card.tutor {
    grid-column: 1;
    grid-row: 3;

    @include media-down(laptop) {
      grid-column: 2;
      grid-row: 3;
    }

    @include media-down(tablet) {
      grid-column: 1;
      grid-row: 2;
      min-height: 138px;
    }
  }
  &__card.language {
    grid-column: 2;
    grid-row: 3;

    @include media-down(laptop) {
      grid-column: 1;
      grid-row: 4;
    }

    @include media-down(tablet) {
      grid-column: 1;
      grid-row: 5;
      min-height: 138px;
    }
  }
  &__card.help {
    grid-column: 3;
    grid-row: 3;

    @include media-down(laptop) {
      grid-column: 2;
      grid-row: 4;
    }

    @include media-down(tablet) {
      grid-column: 1;
      grid-row: 6;
      min-height: 138px;
    }
  }

  &__free {
    grid-column: 3;
    grid-row: 1 / 3;
    position: relative;
    overflow: hidden;

    border-radius: 20px;

    cursor: pointer;
    transition: transform .2s ease-out;

    @include media-down(laptop) {
      grid-column: 2;
    }

    @include media-down(tablet) {
      grid-column: 1;
      grid-row: 7;
      height: 226px;
      overflow: hidden;

      border-radius: 20px;
    }

    &-bg {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      overflow: hidden;

      border-radius: 20px;
      background-color: $header-card-bg;

      transition: .2s ease-out;

      &--image {
        width: 100%;
        height: 100%;

        @include media-down(tablet) {
          display: none;
        }

        &-mobile {
          display: none;

          @include media-down(tablet) {
            display: block;
            position: absolute;
            bottom: 0;
            right: 0;
          }
        }
      }
    }

    &-title {
      position: absolute;
      z-index: 1;
      top: 20px;
      left: 12px;

      @media (max-width: 1220px) {
        max-width: 100%;
        left: 7px;
      }

      @include media-down(laptop) {
        max-width: 90%;
        top: 10px;
      }

      @include media-down(tablet) {
        top: 16px;
      }
    }

    &-text {
      position: absolute;
      z-index: 1;
      top: 72px;
      left: 20px;
      width: 78.43%;

      font-size: 16px;
      font-weight: 600;
      line-height: 110%;
      letter-spacing: -0.16px;
      color: $grey2;

      @media (max-width: 1220px) {
        left: 15px;
        font-size: 14px;
      }

      @include media-down(laptop) {
        top: 57px;
        left: 10px;
      }

      @include media-down(tablet) {
        top: 68px;
        left: 16px;
        font-size: 16px;
      }
    }

    &:hover {
      .child__free-bg {
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        left: 2px;
        top: 2px;
      }
      .child__free-text {
        color: $black-color;
      }
    }
  }
}
</style>
