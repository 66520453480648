<template>
  <div class="tutors">
    <div class="tutors__subjects">
      <nuxt-link
        v-for="item of subjects"
        :key="item.id"
        :to="item.link"
        class="tutors__subjects-card"
      >
        <span>
          <span class="tutors__subjects-card-name">
            {{ item.name }}
          </span>
          <span class="tutors__subjects-card-type">
            {{ item.type }}
          </span>
        </span>

        <img
          :src="item.icon"
          :alt="item.name"
          class="tutors__subjects-card-icon"
        >
      </nuxt-link>
    </div>

    <nuxt-link
      to="/catalog/repetitor"
      class="tutors__free"
    >
      <div class="tutors__free-bg">
        <img
          src="/v2/header/old/dropdown/tutors/free.png"
          alt="heart"
          class="tutors__free-bg--image"
        >
        <img
          src="/v2/header/old/dropdown/tutors/free-mobile.png"
          alt="heart"
          class="tutors__free-bg--image-mobile"
        >
      </div>
      <img
        src="/v2/header/old/dropdown/tutors/free-title.svg"
        alt="большой выбор репетиторов"
        class="tutors__free-title"
      >
      <p class="tutors__free-text">
        Подберите идеального преподавателя по любому
        предмету и цели с помощью удобного каталога
      </p>
    </nuxt-link>

    <div class="tutors__right">
      <div
        class="tutors__card"
        role="button"
        tabindex="0"
        @click="toggleFreeTutorsLead"
      >
        <div class="tutors__card-bg" />

        <h6 class="tutors__card-title">
          <img
            src="/v2/header/old/dropdown/tutors/arrow.svg"
            alt="arrow"
          >
          Попробуйте любого репетитора
          <span>за 0 рублей</span>
        </h6>

        <p class="tutors__card-text">
          Поможем определимся с целями, подтянуть оценки,
          подготовиться к экзаменам и олимпиадам
        </p>
      </div>
      <div
        class="tutors__card"
        role="button"
        tabindex="0"
        @click="toggleFreeTutorsJuniorLead"
      >
        <div class="tutors__card-bg" />

        <h6 class="tutors__card-title">
          <img
            src="/v2/header/old/dropdown/tutors/arrow.svg"
            alt="arrow"
          >
          Дошкольная подготовка
        </h6>

        <p class="tutors__card-text">
          Проведем диагностику готовности ребенка к школе
          и поможем войти в школьную жизнь без волнения
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'DropdownTutorsContent',
  data: () => ({
    mainSubjects: [
      {
        id: 1,
        name: 'Математика',
        type: '1-11 класс, ЕГЭ/ОГЭ, профиль',
        icon: '/v2/header/old/dropdown/tutors/icon-math.svg',
        link: '/catalog/repetitor/matematika',
      },
      {
        id: 2,
        name: 'Литература',
        type: '1-11 класс, ЕГЭ/ОГЭ, профиль',
        icon: '/v2/header/old/dropdown/tutors/icon-literature.svg',
        link: '/catalog/repetitor/literatura',
      },
      {
        id: 3,
        name: 'Русский язык',
        type: '1-11 класс, ЕГЭ/ОГЭ, профиль',
        icon: '/v2/header/old/dropdown/tutors/icon-russian.svg',
        link: '/catalog/repetitor/russkij-yazyk',
      },
      {
        id: 4,
        name: 'Английский язык',
        type: '1-11 класс, ЕГЭ/ОГЭ, профиль',
        icon: '/v2/header/old/dropdown/tutors/icon-english.svg',
        link: 'catalog/repetitor/anglijskij_yazyk',
      },
      {
        id: 5,
        name: 'Физика',
        type: '1-11 класс, ЕГЭ/ОГЭ, профиль',
        icon: '/v2/header/old/dropdown/tutors/icon-phisics.svg',
        link: '/catalog/repetitor/fizika',
      },
      {
        id: 6,
        name: 'Химия',
        type: '1-11 класс, ЕГЭ/ОГЭ, профиль',
        icon: '/v2/header/old/dropdown/tutors/icon-chemistry.svg',
        link: '/catalog/repetitor/himiya',
      },
      {
        id: 7,
        name: 'Биология',
        type: '1-11 класс, ЕГЭ/ОГЭ, профиль',
        icon: '/v2/header/old/dropdown/tutors/icon-biology.svg',
        link: '/catalog/repetitor/biologiya',
      },
      {
        id: 8,
        name: 'Информатика',
        type: '1-11 класс, ЕГЭ/ОГЭ, профиль',
        icon: '/v2/header/old/dropdown/tutors/icon-computer-science.svg',
        link: '/catalog/repetitor/informatika',
      },
      {
        id: 10,
        name: 'Обществознание',
        type: '1-11 класс, ЕГЭ/ОГЭ, профиль',
        icon: '/v2/header/old/dropdown/tutors/icon-social-science.svg',
        link: '/catalog/repetitor/obshchestvoznanie',
      },
      {
        id: 13,
        name: 'География',
        type: '1-11 класс, ЕГЭ/ОГЭ',
        icon: '/v2/header/old/dropdown/tutors/icon-geography.svg',
        link: '/catalog/repetitor/geografiya',
      },
      {
        id: 9,
        name: 'История',
        type: '1-11 класс, ЕГЭ/ОГЭ, профиль',
        icon: '/v2/header/old/dropdown/tutors/icon-history.svg',
        link: '/catalog/repetitor/istoriya',
      },
      {
        id: 11,
        name: 'Испанский язык',
        type: '1-11 класс, ЕГЭ/ОГЭ',
        icon: '/v2/header/old/dropdown/tutors/icon-spanish.svg',
        link: '/catalog/repetitor/ispanskij-yazyk',
      },
      {
        id: 12,
        name: 'Китайский язык',
        type: '1-11 класс, ЕГЭ/ОГЭ',
        icon: '/v2/header/old/dropdown/tutors/icon-chinese.svg',
        link: '/catalog/repetitor/kitajskij-yazyk',
      },
      {
        id: 14,
        name: 'Немецкий язык',
        type: '1-11 класс, ЕГЭ/ОГЭ',
        icon: '/v2/header/old/dropdown/tutors/icon-geography.svg',
        link: '/catalog/repetitor/nemezkij-yazyk',
      },
    ],

    rightSubjects: [
      {
        id: 13,
        name: 'География',
        type: '1-11 класс, ЕГЭ/ОГЭ',
        icon: '/v2/header/old/dropdown/tutors/icon-geography.svg',
        link: '/repetitor',
      },
      {
        id: 14,
        name: 'Немецкий язык',
        type: '1-11 класс, ЕГЭ/ОГЭ',
        icon: '/v2/header/old/dropdown/tutors/icon-geography.svg',
        link: '/repetitor',
      },
    ],
  }),

  computed: {
    ...mapGetters({
      isLaptop: 'isLaptop',
    }),
    subjects() {
      if (!this.isLaptop) return this.mainSubjects;
      return this.mainSubjects.concat(this.rightSubjects);
    },
  },

  methods: {
    ...mapActions('headerModals', [
      'toggleFreeTutorsLead',
      'toggleFreeTutorsJuniorLead',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.tutors {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 306px 1fr;
  grid-gap: 10px;

  @media (max-width: 1220px) {
    //grid-template-rows: 2fr 1.5fr;
  }

  @include media-down(laptop) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
  }

  @include media-down(tablet) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
  }

  &__subjects {
    grid-column: 2;
    grid-row: 1 / 3;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;

    @include media-down(laptop) {
      grid-template-columns: 1fr;
    }

    @include media-down(tablet) {
      grid-column: 1;
      grid-row: 3;
      grid-gap: 8px;
    }

    &-card {
      max-height: 85px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 23px 20px;
      position: relative;

      border-radius: 20px;
      background-color: $header-card-bg;
      box-shadow: 2px 2px 10px 0 rgba(87, 106, 135, 0.02) inset, 8px 8px 30px 0 rgba(87, 106, 135, 0.02) inset;

      cursor: pointer;
      transition: transform .2s ease-out;

      @media (max-width: 1220px) {
        //padding: 10px;
      }

      @include media-down(tablet) {
        padding: 16px;
      }

      span {
        display: block;
      }

      &-name {
        font-variant-numeric: lining-nums proportional-nums;
        font-size: 20px;
        font-weight: 700;
        line-height: 110%;
        letter-spacing: -0.2px;
        text-transform: uppercase;
        color: $black-color;

        @media (max-width: 1220px) {
          font-size: 16px;
        }

        @include media-down(tablet) {
          font-size: 20px;
        }
      }

      &-type {
        margin-top: 4px;

        font-variant-numeric: lining-nums proportional-nums;
        font-feature-settings: 'case' on;
        font-size: 14px;
        font-weight: 500;
        line-height: 110%;
        letter-spacing: -0.28px;
        color: $grey2;

        transition: color .2s ease-out;
      }

      &:hover {
        transform: scale(0.99);

        .tutors__subjects-card-type {
          color: $black-color;
        }
      }
    }
  }

  &__right {
    grid-column: 1;
    grid-row: 2;

    display: flex;
    flex-direction: column;
    gap: 10px;

    @include media-down(tablet) {
      flex-direction: column-reverse;
      grid-column: 1;
      grid-row: 2;
    }
  }

  &__free {
    grid-column: 1;
    grid-row: 1;
    position: relative;
    overflow: hidden;

    border-radius: 20px;

    cursor: pointer;
    transition: transform .2s ease-out;

    @include media-down(tablet) {
      grid-column: 1;
      grid-row: 1;
      height: 226px;
    }

    &-bg {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      overflow: hidden;

      border-radius: 20px;
      background-color: $header-card-bg;

      transition: .2s ease-out;

      &--image {
        width: 100%;
        height: 100%;

        @include media-down(tablet) {
          display: none;
        }

        &-mobile {
          display: none;

          @include media-down(tablet) {
            display: block;
            position: absolute;
            bottom: 0;
            right: 0;
          }
        }
      }
    }

    &-title {
      position: absolute;
      z-index: 1;
      top: 20px;
      left: 12px;

      transition: transform .2s ease-out;

      @media (max-width: 1220px) {
        max-width: 100%;
        left: 7px;
      }

      @include media-down(laptop) {
        max-width: 90%;
        top: 10px;
      }

      @include media-down(tablet) {
        top: 16px;
      }
    }

    &-text {
      position: absolute;
      z-index: 1;
      top: 72px;
      left: 20px;
      width: 78.43%;

      font-size: 16px;
      font-weight: 600;
      line-height: 110%;
      letter-spacing: -0.16px;
      color: $grey2;

      transition: color .2s ease-out;

      @media (max-width: 1220px) {
        left: 15px;
        font-size: 14px;
      }

      @include media-down(laptop) {
        top: 62px;
        left: 10px;
      }

      @include media-down(tablet) {
        top: 68px;
        left: 16px;
        font-size: 16px;
      }
    }

    &:hover {
      .tutors__free-bg {
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        left: 2px;
        top: 2px;
      }
      .tutors__free-text {
        color: $black-color;
      }
    }
  }

  &__card {
    padding: 20px;
    position: relative;

    min-height: 148px;

    border-radius: 20px;

    cursor: pointer;

    @media (max-width: 1220px) {
      padding: 15px;
    }

    @include media-down(laptop) {
      padding: 10px;
    }

    @include media-down(tablet) {
      padding: 16px;
      min-height: 138px;
    }

    &-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      background-color: $header-card-bg;
      border-radius: 20px;
      box-shadow: 2px 2px 10px 0 rgba(87, 106, 135, 0.02) inset, 8px 8px 30px 0 rgba(87, 106, 135, 0.02) inset;

      transition: width .2s ease-out, height .2s ease-out, top .2s ease-out, left .2s ease-out;
    }

    &-title {
      position: relative;
      z-index: 1;

      font-variant-numeric: lining-nums proportional-nums;
      font-size: 20px;
      font-weight: 700;
      line-height: 110%;
      letter-spacing: -0.2px;
      text-transform: uppercase;
      color: $black-color;

      @media (max-width: 1220px) {
        font-size: 18px;
      }

      @include media-down(laptop) {
        font-size: 15px;
      }

      @include media-down(tablet) {
        font-size: 20px;
      }

      img {
        display: inline;
        transform: translateY(1.5px);

        @media (max-width: 1220px) {
          max-height: 16px;
          transform: translateY(0);
        }

        @include media-down(laptop) {
          max-height: 12px;
        }

        @include media-down(tablet) {
          max-height: 16px;
        }
      }

      span {
        color: #9579DA;
      }
    }

    &-text {
      position: relative;
      z-index: 1;
      margin-top: 8px;

      font-variant-numeric: lining-nums proportional-nums;
      font-size: 16px;
      font-weight: 600;
      line-height: 110%;
      letter-spacing: -0.16px;
      color: $grey2;

      transition: color .2s ease-out;

      @media (max-width: 1220px) {
        font-size: 14px;
      }

      @include media-down(tablet) {
        font-size: 16px;
      }
    }

    &:hover {
      .tutors__card-bg {
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        top: 2px;
        left: 2px;
      }
      .tutors__card-text {
        color: $black-color;
      }
    }
  }

  .tutors__subjects-card--large {
    max-height: 100%;
    grid-row-start: 1;
    grid-row-end: 3;
    align-items: start;

    .tutors__subjects-card-name img {
      position: relative;
      top: 2px;

      @media (max-width: 1220px) {
        height: 16px;
      }

      @include media-down(tablet) {
        height: 18px;
      }
    }

    .tutors__subjects-card-type {
      margin-top: 8px;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
</style>
