<template>
  <div
    ref="freeContainer"
    class="free"
  >
    <div
      class="free__posts"
      :style="sliderStyle"
    >
      <swiper
        ref="swiperPosts"
        :options="swiperPostsOptions"
      >
        <swiper-slide
          v-for="post of posts"
          :key="post.id"
          class="free__slide"
        >
          <img
            :src="post.image"
            :alt="post.title"
          >

          <p class="free__slide-category">
            {{ post.category }}
          </p>

          <nuxt-link
            :to="post.url"
            class="free__slide-title"
          >
            {{ post.title }}
          </nuxt-link>
        </swiper-slide>
      </swiper>

      <div class="free__posts-pagination" />
    </div>

    <div
      class="free__news"
      :style="sliderStyle"
    >
      <swiper
        ref="swiperNews"
        :options="swiperNewsOptions"
      >
        <swiper-slide
          v-for="item of news"
          :key="item.id"
          class="free__slide"
        >
          <img
            :src="item.image"
            :alt="item.title"
          >

          <p class="free__slide-category">
            {{ item.category }}
          </p>

          <nuxt-link
            :to="item.url"
            class="free__slide-title"
          >
            {{ item.title }}
          </nuxt-link>
        </swiper-slide>
      </swiper>

      <div class="free__news-pagination" />
    </div>

    <div
      class="free__free"
      role="button"
      tabindex="0"
      @click="toggleFreeFreeLead"
    >
      <div class="free__free-bg">
        <img
          src="/v2/header/old/dropdown/free/free.png"
          alt="heart"
          class="free__free-bg--image"
        >
        <img
          src="/v2/header/old/dropdown/free/free-mobile.png"
          alt="heart"
          class="free__free-bg--image-mobile"
        >
      </div>

      <img
        src="/v2/header/old/dropdown/free/free-title.svg"
        alt="Начните учиться бесплатно"
        class="free__free-title"
      >
      <p class="free__free-text">
        Получите бесплатный доступ к образовательной платформе на 7 дней
      </p>
    </div>

    <nuxt-link
      to="/articles"
      class="free__card posts"
    >
      <div class="free__card-bg" />

      <h6 class="free__card-title">
        <img
          src="/v2/header/old/dropdown/free/arrow.svg"
          alt="arrow"
        >
        Еще больше статей
      </h6>

      <p class="free__card-text">
        Инсайты, знания, увлекательные и волнующие
        темы из мира детской психологии и образования
      </p>
    </nuxt-link>

    <nuxt-link
      to="/news"
      class="free__card news"
    >
      <div class="free__card-bg" />

      <h6 class="free__card-title">
        <img
          src="/v2/header/old/dropdown/free/arrow.svg"
          alt="arrow"
        >
        Еще больше новостей
      </h6>

      <p class="free__card-text">
        Актуальные и полезные новости в сфере школьного образования
      </p>
    </nuxt-link>

    <nuxt-link
      to="/proforientacia"
      class="free__card consultation"
    >
      <div class="free__card-bg" />

      <h6 class="free__card-title">
        <img
          src="/v2/header/old/dropdown/free/arrow.svg"
          alt="arrow"
        >
        ПРОФОРИЕНТАЦИЯ для 5-11 классов
      </h6>

      <p class="free__card-text">
        Запишитесь на онлайн-консультацию с экспертом по профориентации.
        <br>
        <span>Начните бесплатно с профтеста</span>
      </p>
    </nuxt-link>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

export default {
  name: 'DropdownFreeContent',
  components: {
    Swiper,
    SwiperSlide,
  },

  data: () => ({
    posts: [
      {
        id: 1,
        category: 'Обучение',
        title: 'Колледж, техникум и училище: в чем разница',
        image: '/v2/header/old/dropdown/free/post-1.jpeg',
        url: '/education/kolledzh-tekhnikum-i-uchilische-v-chem-raznitsa',
      },
      {
        id: 2,
        category: 'Обучение',
        title: 'Популярные профессии, связанные с химией',
        image: '/v2/header/old/dropdown/free/post-2.jpeg',
        url: '/education/populyarnye-professii-svyazannye-s-himiej',
      },
      {
        id: 3,
        category: 'Обучение',
        title: 'Топ-10 лучших детских книг для изучения английского языка',
        image: '/v2/header/old/dropdown/free/post-3.jpeg',
        url: '/education/top-luchshih-detskih-knig-dlya-izucheniya-anglijskogo-yazyka',
      },
      {
        id: 4,
        category: 'ЕГЭ',
        title: 'Итоговое сочинение 2023-2024: темы, структура, рекомендации',
        image: '/v2/header/old/dropdown/free/post-4.jpeg',
        url: '/education/itogovoe-sochinenie-2023-2024-temy-struktura-rekomendacii',
      },
    ],
    activePost: 1,
    news: [
      {
        id: 1,
        category: 'Новости',
        title: 'Как закончить школу с золотой медалью и получить 100 баллов на ЕГЭ?',
        image: '/v2/header/old/dropdown/free/news-1.jpeg',
        url: '/news/kak-zakonchit-shkolu-s-zolotoy-medalyu-i-poluchit--ballov-na-ege',
      },
      {
        id: 2,
        category: 'Новости',
        title: 'Секретами успешной сдачи ЕГЭ поделились 300-балльники',
        image: '/v2/header/old/dropdown/free/news-2.jpeg',
        url: '/news/sekretami-uspeshnoy-sdachi-ege-podelilis--ballniki',
      },
      {
        id: 3,
        category: 'Новости',
        title: 'Искусственный интеллект и большие данные – рассказываем об образовании будущего!',
        image: '/v2/header/old/dropdown/free/news-3.jpeg',
        url: '/news/iskusstvennyj-intellekt-i-bolshie-dannye-rasskazyvaem-ob-obrazovanii-budushhego',
      },
      {
        id: 4,
        category: 'Новости',
        title: 'Директор медицинского факультета рассказал о карьерных возможностях в сфере здравоохранения',
        image: '/v2/header/old/dropdown/free/news-4.jpeg',
        url: '/news/56',
      },
    ],
    activeNews: 1,
    swiperPostsOptions: {
      slidesPerView: '1',
      spaceBetween: 0,
      loop: true,
      speed: 1000,
      autoplay: {
        enabled: true,
        delay: 2000,
      },
      pagination: {
        el: '.free__posts-pagination',
        clickable: true,
      },
    },
    swiperNewsOptions: {
      slidesPerView: '1',
      spaceBetween: 0,
      loop: true,
      speed: 1000,
      autoplay: {
        enabled: true,
        delay: 2000,
      },
      pagination: {
        el: '.free__news-pagination',
        clickable: true,
      },
    },
    sliderStyle: null,
  }),
  computed: {
    ...mapGetters([
      'getWindowSize',
    ]),
  },

  mounted() {
    this.calculateSliderWidth();
    window.addEventListener('resize', this.calculateSliderWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calculateSliderWidth);
  },

  methods: {
    ...mapActions('headerModals', [
      'toggleFreeFreeLead',
      'toggleFreeFreeConsultationLead',
    ]),
    calculateSliderWidth() {
      const containerWidth = document
        .querySelector('#headerComponent')
        .getBoundingClientRect()
        .width;

      let result = 0;

      if (this.getWindowSize > 1220) {
        result = containerWidth * 0.24;
      } else if (this.getWindowSize <= 1220 && this.getWindowSize > 1024) {
        result = (containerWidth - 309) / 2;
      } else if (this.getWindowSize <= 1024 && this.getWindowSize > 768) {
        result = (containerWidth - 264) / 2;
      } else {
        result = containerWidth - 31;
      }

      this.sliderStyle = {
        maxWidth: `${result}px`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.free {
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 3fr 1.45fr;
  grid-gap: 10px;

  @media (max-width: 1220px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto);
  }

  @include media-down(tablet) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, auto);
  }

  &__posts {
    max-width: 306px;
    height: 100%;
    position: relative;

    @include media-down(tablet) {
      grid-column: 1;
      grid-row: 1;
    }

    &-pagination {
      position: absolute;
      bottom: 6px;
      left: 50%;
      transform: translate(-50%);
      display: flex;
      justify-content: center;
      z-index: 1;

      ::v-deep span {
        display: block;
        width: 16px;
        height: 16px;
        position: relative;

        border: none;
        opacity: 1;
        background: transparent;

        cursor: pointer;

        &::before {
          content: '';
          position: absolute;
          left: 4px;
          top: 4px;
          display: block;
          width: 8px;
          height: 8px;
          border: 2px solid $black-color;
          border-radius: 4px;
          opacity: 1;
          background: transparent;
        }
      }
      ::v-deep span.swiper-pagination-bullet-active {

        &::before {
          background: #A8FF53;
        }
      }
    }
  }

  &__news {
    max-width: 306px;
    height: 100%;
    position: relative;

    @include media-down(tablet) {
      display: none;
    }

    &-pagination {
      position: absolute;
      bottom: 6px;
      left: 50%;
      transform: translate(-50%);
      display: flex;
      justify-content: center;
      z-index: 1;

      ::v-deep span {
        display: block;
        width: 16px;
        height: 16px;
        position: relative;

        border: none;
        opacity: 1;
        background: transparent;

        cursor: pointer;

        &::before {
          content: '';
          position: absolute;
          left: 4px;
          top: 4px;
          display: block;
          width: 8px;
          height: 8px;
          border: 2px solid $black-color;
          border-radius: 4px;
          opacity: 1;
          background: transparent;
        }
      }
      ::v-deep span.swiper-pagination-bullet-active {

        &::before {
          background: #A8FF53;
        }
      }
    }
  }

  &__slide {
    height: 100%;
    padding: 10px;

    background-color: $header-card-bg;
    border-radius: 20px;

    img {
      width: 100%;
      border-radius: 10px;
    }

    &-category {
      padding: 10px 10px 8px;

      font-variant-numeric: lining-nums proportional-nums;
      font-feature-settings: 'case' on;
      font-size: 14px;
      font-weight: 500;
      line-height: 110%;
      letter-spacing: -0.28px;
      color: $grey2;

      @media (max-width: 1220px) {
        padding: 10px 5px 8px;
      }

      @include media-down(tablet) {
        padding: 10px 6px 8px;
      }
    }

    &-title {
      display: -webkit-box;
      padding: 0 10px 10px;
      margin-bottom: 20px;
      height: 3.3em;
      max-height: 3.3em;

      font-variant-numeric: lining-nums proportional-nums;
      font-size: 20px;
      font-weight: 700;
      line-height: 110%;
      letter-spacing: -0.2px;
      text-transform: uppercase;
      color: $black-color;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      @media (max-width: 1220px) {
        margin-bottom: 20px;
        padding: 0 5px 10px;
        font-size: 18px;
      }

      @include media-down(laptop) {
        font-size: 15px;
      }

      @include media-down(tablet) {
        padding: 0 6px 14px;
        font-size: 20px;
        margin-bottom: 40px;
      }
    }
  }

  &__free {
    grid-column: 3;
    grid-row: 1;
    position: relative;
    overflow: hidden;
    min-height: 280px;

    border-radius: 20px;

    cursor: pointer;
    transition: transform .2s ease-out;

    @media (max-width: 1220px) {
      grid-column: 1;
      grid-row: 2;
    }

    @include media-down(tablet) {
      grid-column: 1;
      grid-row: 5;
      min-height: 226px;
      height: 226px;
      overflow: hidden;

      border-radius: 20px;
    }

    &-bg {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      overflow: hidden;

      border-radius: 20px;
      background-color: $header-card-bg;

      transition: .2s ease-out;

      &--image {
        width: 100%;
        height: 100%;

        @include media-down(tablet) {
          display: none;
        }

        &-mobile {
          display: none;

          @include media-down(tablet) {
            display: block;
            position: absolute;
            bottom: 0;
            right: 0;
          }
        }
      }
    }

    &-title {
      position: absolute;
      z-index: 1;
      top: 20px;
      left: 12px;

      @media (max-width: 1220px) {
        max-width: 100%;
        left: 7px;
      }

      @include media-down(laptop) {
        max-width: 90%;
        top: 10px;
      }

      @include media-down(tablet) {
        top: 16px;
      }
    }

    &-text {
      position: absolute;
      z-index: 1;
      top: 72px;
      left: 20px;
      width: 78.43%;

      font-size: 16px;
      font-weight: 600;
      line-height: 110%;
      letter-spacing: -0.16px;
      color: $grey2;

      transition: color .2s ease-out;

      @media (max-width: 1220px) {
        left: 15px;
        font-size: 14px;
      }

      @include media-down(laptop) {
        top: 57px;
        left: 10px;
      }

      @include media-down(tablet) {
        top: 68px;
        left: 16px;
        font-size: 16px;
      }
    }

    &:hover {
      .free__free-bg {
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        left: 2px;
        top: 2px;
      }
      .free__free-text {
        color: $black-color;
      }
    }
  }

  &__card {
    position: relative;
    padding: 20px;

    border-radius: 20px;

    cursor: pointer;

    @media (max-width: 1220px) {
      padding: 15px;
    }

    @include media-down(laptop) {
      padding: 10px;
    }

    @include media-down(tablet) {
      padding: 16px;
      min-height: 138px;
    }

    &-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      background-color: $header-card-bg;
      border-radius: 20px;
      box-shadow: 2px 2px 10px 0 rgba(87, 106, 135, 0.02) inset, 8px 8px 30px 0 rgba(87, 106, 135, 0.02) inset;

      transition: width .2s ease-out, height .2s ease-out, top .2s ease-out, left .2s ease-out;
    }

    &-title {
      position: relative;
      z-index: 1;

      font-variant-numeric: lining-nums proportional-nums;
      font-size: 20px;
      font-weight: 700;
      line-height: 110%;
      letter-spacing: -0.2px;
      text-transform: uppercase;
      color: $black-color;

      @media (max-width: 1220px) {
        min-height: 1px;

        font-size: 18px;
      }

      @include media-down(laptop) {
        font-size: 15px;
      }

      @include media-down(tablet) {
        font-size: 20px;
      }

      img {
        display: inline;
        transform: translateY(1.5px);

        @media (max-width: 1220px) {
          max-height: 16px;
          transform: translateY(0);
        }

        @include media-down(laptop) {
          max-height: 12px;
        }

        @include media-down(tablet) {
          max-height: 16px;
        }
      }
    }

    &-text {
      position: relative;
      z-index: 1;
      margin-top: 8px;

      font-variant-numeric: lining-nums proportional-nums;
      font-size: 16px;
      font-weight: 600;
      line-height: 110%;
      letter-spacing: -0.16px;
      color: $grey2;

      transition: color .2s ease-out;

      @media (max-width: 1220px) {
        font-size: 14px;
      }

      @include media-down(tablet) {
        font-size: 16px;
      }

      span {
        color: #89C053;
      }
    }

    &:hover {
      .free__card-bg {
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        top: 2px;
        left: 2px;
      }
      .free__card-subtitle {
        color: $black-color;
      }
      .free__card-text {
        color: $black-color;
      }
    }
  }

  &__card.posts {
    grid-column: 1;
    grid-row: 2;

    @media (max-width: 1220px) {
      grid-column: 2;
      grid-row: 2;
    }

    @include media-down(tablet) {
      grid-column: 1;
      grid-row: 2;
    }
  }
  &__card.news {
    grid-column: 2;
    grid-row: 2;

    @media (max-width: 1220px) {
      grid-column: 1;
      grid-row: 3;
    }

    @include media-down(tablet) {
      grid-column: 1;
      grid-row: 3;
    }
  }
  &__card.consultation {
    grid-column: 3;
    grid-row: 2;

    @media (max-width: 1220px) {
      grid-column: 2;
      grid-row: 3;
    }

    @include media-down(tablet) {
      grid-column: 1;
      grid-row: 4;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
