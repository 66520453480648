var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dropdown-mobile",class:{ 'active': _vm.activeTabs.length }},[_c('div',{staticClass:"dropdown-mobile__list"},_vm._l((_vm.navigationTabs),function(item,idx){return _c('div',{key:item.id,staticClass:"dropdown-mobile__item",class:{ 'active': item.isActive }},[_c('div',{class:['dropdown-mobile__item-title', { 'active': item.isActive }],attrs:{"role":"button","tabindex":"0"},on:{"click":function($event){return _vm.toggleTab(item.label)}}},[_c('span',[_vm._v("\n          "+_vm._s(item.label)+"\n        ")]),_vm._v(" "),_c('img',{attrs:{"src":item.icon,"alt":"arrow"}})]),_vm._v(" "),_c('div',{staticClass:"dropdown-mobile__item-content",class:[
          'dropdown-mobile__item-content--' + idx,
          {
            'active' : item.isActive
          }
        ],style:({
          height: !item.isActive ? 0 : _vm.getHeight(idx),
          'margin-top': !item.isActive ? 0 : '10px',
        })},[_c(item.component,{tag:"component",attrs:{"content-type":item.contentType}})],1)])}),0),_vm._v(" "),_c('div',{staticClass:"dropdown-mobile__social"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"dropdown-mobile__social-links"},[_vm._m(1),_vm._v(" "),_c('a',{attrs:{"href":_vm.$getTelegramLink(_vm.$route),"target":"_blank","rel":"nofollow"}},[_c('img',{attrs:{"src":"/v2/header/old/dropdown/telegram.svg","alt":"telegram"}})]),_vm._v(" "),_vm._m(2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"dropdown-mobile__social-title"},[_vm._v("\n      Напишите нам,\n      "),_c('br'),_vm._v("\n      если есть вопросы\n    ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://wa.me/79686286327","target":"_blank","rel":"nofollow"}},[_c('img',{attrs:{"src":"/v2/header/old/dropdown/whatsapp.svg","alt":"whatsapp"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"mailto:nstolbova@synergy.ru","target":"_blank"}},[_c('img',{attrs:{"src":"/v2/header/old/dropdown/email.svg","alt":"email"}})])
}]

export { render, staticRenderFns }