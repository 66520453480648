<template>
  <div class="ege-oge">
    <div class="ege-oge__subjects">
      <nuxt-link
        v-if="contentType === CONTENT_TYPE.oge"
        to="/oge"
        class="ege-oge__subjects-card ege-oge__subjects-card--large"
      >
        <span>
          <span class="ege-oge__subjects-card-name">
            <img
              src="/v2/header/old/dropdown/egeoge/arrow.svg"
              alt="arrow"
            >
            Комплексная подготовка к ОГЭ 2024
          </span>
          <span class="ege-oge__subjects-card-type">
            Подготовим к&nbsp;ОГЭ на&nbsp;4&nbsp;и&nbsp;5 для&nbsp;поступления
            в&nbsp;колледж или&nbsp;красного аттестата
          </span>
        </span>
      </nuxt-link>
      <nuxt-link
        v-if="contentType === CONTENT_TYPE.ege"
        to="/ege"
        class="ege-oge__subjects-card ege-oge__subjects-card--large"
      >
        <span>
          <span class="ege-oge__subjects-card-name">
            <img
              src="/v2/header/old/dropdown/egeoge/arrow.svg"
              alt="arrow"
            >
            Комплексная подготовка к ЕГЭ 2024
          </span>
          <span class="ege-oge__subjects-card-type">
            Подготовим к&nbsp;ЕГЭ на&nbsp;высокий балл, поможем выбрать
            профессию мечты и&nbsp;поступить в&nbsp;вуз на&nbsp;бюджет
          </span>
        </span>
      </nuxt-link>
      <nuxt-link
        v-for="item of activeSubjects"
        :key="item.id"
        :to="item.link"
        :class="['ege-oge__subjects-card', { 'disabled': item.disabled }]"
      >
        <span>
          <span class="ege-oge__subjects-card-name">
            {{ item.name }}
          </span>
          <span class="ege-oge__subjects-card-type">
            {{ item.type }}
          </span>
        </span>

        <img
          :src="item.icon"
          :alt="item.name"
          class="ege-oge__subjects-card-icon"
        >

        <img
          v-if="item.disabled"
          src="/v2/header/old/dropdown/egeoge/soon.png"
          alt="soon"
          class="ege-oge__subjects-card-sticker"
        >
      </nuxt-link>
    </div>

    <div
      v-if="contentType === CONTENT_TYPE.oge"
      class="ege-oge__free"
      role="button"
      tabindex="0"
      @click="toggleFreeEgeOgeLead"
    >
      <div class="ege-oge__free-bg">
        <img
          src="/v2/header/old/dropdown/egeoge/free.png"
          alt="heart"
          class="ege-oge__free-bg--image"
        >
        <img
          src="/v2/header/old/dropdown/egeoge/free-mobile.png"
          alt="heart"
          class="ege-oge__free-bg--image-mobile"
        >
      </div>
      <img
        src="/v2/header/old/dropdown/egeoge/free-title.svg"
        alt="бесплатный пробный день"
        class="ege-oge__free-title"
      >
      <p class="ege-oge__free-text">
        Разберите одну тему из экзамена на живом занятии
      </p>
    </div>

    <nuxt-link
      v-if="contentType === CONTENT_TYPE.ege"
      to="/free/ege/kurs-ege-2024"
      class="ege-oge__free"
    >
      <div class="ege-oge__free-bg">
        <img
          src="/v2/header/old/dropdown/egeoge/free-marathon.png"
          alt="+100"
          class="ege-oge__free-bg--image"
        >
        <img
          src="/v2/header/old/dropdown/egeoge/free-marathon-mobile.png"
          alt="+100"
          class="ege-oge__free-bg--image-mobile"
        >
      </div>
      <img
        src="/v2/header/old/dropdown/egeoge/free-title-marathon.svg"
        alt="бесплатный марафон егэ"
        class="ege-oge__free-title"
      >
      <p class="ege-oge__free-text">
        Детальный разбор и стратегия подготовки к ЕГЭ по русскому языку и профильной математике
      </p>
    </nuxt-link>

    <div class="ege-oge__card__wrapper">
      <nuxt-link
        v-if="contentType === CONTENT_TYPE.oge"
        to="/proforientacia"
        class="ege-oge__card"
      >
        <div class="ege-oge__card-bg" />

        <h6 class="ege-oge__card-title">
          <img
            src="/v2/header/old/dropdown/egeoge/arrow.svg"
            alt="arrow"
          >
          ПРОФОРИЕНТАЦИЯ для 5-11 классов
        </h6>

        <p class="ege-oge__card-text">
          Запишитесь на онлайн-консультацию с экспертом по профориентации.
          <br>
          <span>Начните бесплатно с профтеста</span>
        </p>
      </nuxt-link>
      <nuxt-link
        v-if="contentType === CONTENT_TYPE.ege"
        to="/proforientacia"
        class="ege-oge__card"
      >
        <div class="ege-oge__card-bg" />

        <h6 class="ege-oge__card-title">
          <img
            src="/v2/header/old/dropdown/egeoge/arrow.svg"
            alt="arrow"
          >
          ПРОФОРИЕНТАЦИЯ для 5-11 классов
        </h6>

        <p class="ege-oge__card-text">
          Запишитесь на онлайн-консультацию с экспертом по профориентации.
          <br>
          <span>Начните бесплатно с профтеста</span>
        </p>
      </nuxt-link>

      <nuxt-link
        v-if="!isLaptop && contentType === CONTENT_TYPE.ege"
        to="/ege/obshchestvoznanie"
        class="ege-oge__subjects-card"
      >
        <span>
          <span class="ege-oge__subjects-card-name">
            Обществознание
          </span>
          <span class="ege-oge__subjects-card-type">
            ЕГЭ
          </span>
        </span>

        <img
          src="/v2/header/old/dropdown/egeoge/icon-social-science.svg"
          alt="Обществознание"
          class="ege-oge__subjects-card-icon"
        >
      </nuxt-link>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { SUBJECTS } from '~/data/constants';
import { CONTENT_TYPE } from './constants';

export default {
  name: 'DropdownEgeOgeContent',
  props: {
    contentType: {
      type: String,
      default: CONTENT_TYPE.ege,
    },
  },
  data: () => ({
    subjects: [
      {
        name: 'Математика',
        icon: '/v2/header/old/dropdown/egeoge/icon-math.svg',
        link: `/${SUBJECTS.matematika}`,
      },
      {
        name: 'Русский язык',
        icon: '/v2/header/old/dropdown/egeoge/icon-russian.svg',
        link: `/${SUBJECTS.russkijYazyk}`,
      },
      {
        name: 'Литература',
        icon: '/v2/header/old/dropdown/egeoge/literatura.svg',
        link: `/${SUBJECTS.literatura}`,
      },
      {
        name: 'Английский язык',
        icon: '/v2/header/old/dropdown/egeoge/icon-english.svg',
        link: `/${SUBJECTS.anglijskijYazyk}`,
      },
      {
        name: 'Физика',
        icon: '/v2/header/old/dropdown/egeoge/icon-phisics.svg',
        link: `/${SUBJECTS.fizika}`,
      },
      {
        name: 'Химия',
        icon: '/v2/header/old/dropdown/egeoge/icon-chemistry.svg',
        link: `/${SUBJECTS.himiya}`,
      },
      {
        name: 'Биология',
        icon: '/v2/header/old/dropdown/egeoge/icon-biology.svg',
        link: `/${SUBJECTS.biologiya}`,
      },
      {
        name: 'Информатика',
        icon: '/v2/header/old/dropdown/egeoge/icon-computer-science.svg',
        link: `/${SUBJECTS.informatika}`,
      },
      {
        name: 'История',
        icon: '/v2/header/old/dropdown/egeoge/icon-history.svg',
        link: `/${SUBJECTS.istoriya}`,
      },
      {
        name: 'Обществознание',
        icon: '/v2/header/old/dropdown/egeoge/icon-social-science.svg',
        link: `/${SUBJECTS.obshchestvoznanie}`,
      },
    ],
    CONTENT_TYPE,
  }),
  computed: {
    ...mapGetters({
      isTablet: 'isTablet',
      isLaptop: 'isLaptop',
    }),

    ogeSubjects() {
      return this.subjects.map((item, idx) => ({
        id: idx + 1,
        name: item.name,
        type: 'ОГЭ',
        icon: item.icon,
        link: `/oge${item.link}`,
        disabled: false,
      }));
    },
    egeSubjects() {
      const mathItems = [
        {
          id: 1,
          name: 'Математика',
          type: 'ЕГЭ (профиль)',
          icon: '/v2/header/old/dropdown/egeoge/icon-math.svg',
          link: `/ege/${SUBJECTS.matematikaProfil}`,
          disabled: false,
        },
        {
          id: 2,
          name: 'Математика',
          type: 'ЕГЭ (база)',
          icon: '/v2/header/old/dropdown/egeoge/icon-math.svg',
          link: `/ege/${SUBJECTS.matematika}`,
          disabled: false,
        },
      ];

      return [
        ...mathItems,
        ...this.subjects
          .filter((item) => {
            if (this.isLaptop) return item.name !== 'Математика';
            return item.name !== 'Математика' && item.name !== 'Обществознание';
          })
          .map((item, idx) => ({
            id: idx + 3,
            name: item.name,
            type: 'ЕГЭ',
            icon: item.icon,
            link: `/ege${item.link}`,
            disabled: false,
          })),
      ];
    },
    activeSubjects() {
      if (this.contentType === CONTENT_TYPE.ege) return this.egeSubjects;

      if (this.contentType === CONTENT_TYPE.oge) return this.ogeSubjects;

      return [];
    },
  },

  methods: {
    ...mapActions('headerModals', [
      'toggleFreeEgeOgeLead',
      'toggleFreeEgeOgeProfessionalLead',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.ege-oge {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 306px 1fr;
  grid-gap: 10px;

  @media (max-width: 1220px) {
    grid-template-rows: 2fr 1.5fr;
  }

  @include media-down(laptop) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
  }

  @include media-down(tablet) {
    grid-template-columns: 100%;
    grid-template-rows: repeat(3, auto);
  }

  &__subjects {
    grid-column: 1;
    grid-row: 1 / 3;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;

    @include media-down(laptop) {
      grid-template-columns: 1fr;
    }

    @include media-down(tablet) {
      grid-column: 1;
      grid-row: 1;
      grid-gap: 8px;
    }

    &-card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 21px 20px;
      position: relative;

      border-radius: 20px;
      background-color: $header-card-bg;
      box-shadow: 2px 2px 10px 0 rgba(87, 106, 135, 0.02) inset, 8px 8px 30px 0 rgba(87, 106, 135, 0.02) inset;

      cursor: pointer;
      transition: transform .2s ease-out;

      @media (max-width: 1220px) {
        padding: 10px;
      }

      @include media-down(tablet) {
        padding: 16px;
      }

      span {
        display: block;
      }

      &-name {
        font-variant-numeric: lining-nums proportional-nums;
        font-size: 20px;
        font-weight: 700;
        line-height: 110%;
        letter-spacing: -0.2px;
        text-transform: uppercase;
        color: $black-color;

        @media (max-width: 1220px) {
          font-size: 16px;
        }

        @include media-down(tablet) {
          font-size: 20px;
        }
      }

      &-type {
        margin-top: 4px;

        font-variant-numeric: lining-nums proportional-nums;
        font-feature-settings: 'case' on;
        font-size: 14px;
        font-weight: 500;
        line-height: 110%;
        letter-spacing: -0.28px;
        color: #919191;
        transition: color .2s ease-out;
      }

      &-sticker {
        width: 64px;
        position: absolute;
        bottom: -3.75px;
        right: -3.79px;

        @include media-down(tablet) {
          right: 0;
        }
      }

      &:hover {
        transform: scale(0.98);

        .ege-oge__subjects-card-type {
          color: $black-color;
        }
      }
    }
    &-card.disabled {
      pointer-events: none;

      .ege-oge__subjects-card-name {
        color: #919191;
      }

      .ege-oge__subjects-card-icon {
        filter: grayscale(1);
      }
    }
  }

  &__free {
    grid-column: 2;
    grid-row: 1;
    position: relative;
    overflow: hidden;

    border-radius: 20px;

    cursor: pointer;
    transition: transform .2s ease-out;

    @include media-down(tablet) {
      grid-column: 1;
      grid-row: 2;
      height: 226px;
    }

    &-bg {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      overflow: hidden;

      border-radius: 20px;
      background-color: $header-card-bg;

      transition: .2s ease-out;

      &--image {
        width: 100%;
        height: 100%;

        @include media-down(tablet) {
          display: none;
        }

        &-mobile {
          display: none;

          @include media-down(tablet) {
            display: block;
            position: absolute;
            bottom: 0;
            right: 0;
          }
        }
      }
    }

    &-title {
      position: absolute;
      z-index: 1;
      top: 20px;
      left: 12px;

      @media (max-width: 1220px) {
        max-width: 100%;
        left: 7px;
      }

      @include media-down(laptop) {
        max-width: 90%;
        top: 10px;
      }

      @include media-down(tablet) {
        top: 16px;
      }
    }

    &-text {
      position: absolute;
      z-index: 1;
      top: 72px;
      left: 20px;
      width: 78.43%;

      font-size: 16px;
      font-weight: 600;
      line-height: 110%;
      letter-spacing: -0.16px;
      color: #919191;

      transition: color .2s ease-out;

      @media (max-width: 1220px) {
        left: 15px;
        font-size: 14px;
      }

      @include media-down(laptop) {
        top: 62px;
        left: 10px;
      }

      @include media-down(tablet) {
        top: 68px;
        left: 16px;
        font-size: 16px;
      }
    }

    &:hover {
      .ege-oge__free-bg {
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        left: 2px;
        top: 2px;
      }
      .ege-oge__free-text {
        color: $black-color;
      }
    }
  }

  &__card__wrapper {
    grid-column: 2;
    grid-row: 2;

    display: flex;
    flex-direction: column;
    gap: 10px;

    @include media-down(tablet) {
      grid-column: 1;
      grid-row: 3;
    }
  }

  &__card {
    height: 148px;
    position: relative;
    padding: 20px;

    border-radius: 20px;

    cursor: pointer;

    @media (max-width: 1220px) {
      padding: 15px;
    }

    @include media-down(laptop) {
      padding: 10px;
    }

    @include media-down(tablet) {
      grid-column: 1;
      grid-row: 2;
      padding: 16px;
      min-height: 138px;
    }

    &-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      background-color: $header-card-bg;
      border-radius: 20px;
      box-shadow: 2px 2px 10px 0 rgba(87, 106, 135, 0.02) inset, 8px 8px 30px 0 rgba(87, 106, 135, 0.02) inset;

      transition: width .2s ease-out, height .2s ease-out, top .2s ease-out, left .2s ease-out;
    }

    &-title {
      position: relative;
      z-index: 1;

      font-variant-numeric: lining-nums proportional-nums;
      font-size: 20px;
      font-weight: 700;
      line-height: 110%;
      letter-spacing: -0.2px;
      text-transform: uppercase;
      color: $black-color;

      @media (max-width: 1220px) {
        font-size: 18px;
      }

      @include media-down(laptop) {
        font-size: 15px;
      }

      @include media-down(tablet) {
        font-size: 20px;
      }

      img {
        display: inline;
        transform: translateY(1.5px);

        @media (max-width: 1220px) {
          max-height: 16px;
          transform: translateY(0);
        }

        @include media-down(laptop) {
          max-height: 12px;
        }

        @include media-down(tablet) {
          max-height: 16px;
        }
      }
    }

    &-text {
      position: relative;
      z-index: 1;
      margin-top: 8px;

      font-variant-numeric: lining-nums proportional-nums;
      font-size: 16px;
      font-weight: 600;
      line-height: 110%;
      letter-spacing: -0.16px;
      color: #919191;

      transition: color .2s ease-out;

      @media (max-width: 1220px) {
        font-size: 14px;
      }

      @include media-down(tablet) {
        font-size: 16px;
      }

      span {
        color: $blue-dark;
      }
    }

    &:hover {
      .ege-oge__card-bg {
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        top: 2px;
        left: 2px;
      }
      .ege-oge__card-subtitle {
        color: $black-color;
      }
      .ege-oge__card-text {
        color: $black-color;
      }
    }
  }

  .ege-oge__subjects-card--large {
    grid-row-start: 1;
    grid-row-end: 3;
    align-items: start;

    .ege-oge__subjects-card-name img {
      position: relative;
      top: 2px;

      @media (max-width: 1220px) {
        height: 16px;
      }

      @include media-down(tablet) {
        height: 18px;
      }
    }

    .ege-oge__subjects-card-type {
      margin-top: 8px;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
</style>
